<template>
  <section class="pd-20">
    <aPageHeader class="pd-0">
      <template #title>
        <h1>
          <img
            src="@/assets/images/dashboard/finances/finances.png"
            alt="ico"
            width="30"
          />
          Faturas
        </h1>
      </template>
    </aPageHeader>

    <InvoicesListFiltersCollapse
      :filters="invoice.filters"
      :loading="invoice.loading"
      @onClickFilterInvoices="onClickFilterInvoices"
    />

    <aTable
      class="travel-table"
      :columns="invoicesTableColumns"
      :data-source="invoice.list"
      :loading="invoice.loading"
      :pagination="{ pageSize: 30, showSizeChanger: true }"
      :scroll="{ x: 1300 }"
    >
      <div slot="product_supplier" slot-scope="record">
        <div class="dotted-phrase upper">
          <a-tooltip :title="record.product">
            {{ record.product_supplier.id }} -
            {{ record.product_supplier.company_name }}
          </a-tooltip>
        </div>
      </div>

      <div slot="value" slot-scope="record">
        {{ record.value | formatPricePtBr }}
      </div>

      <div slot="balance_value" slot-scope="record">
        {{ record.balance_value.replace("-", "") | formatPricePtBr }}
      </div>

      <div slot="company" slot-scope="record">
        <div class="dotted-phrase upper">
          {{ record.company.trading_name }}
        </div>
      </div>

      <div slot="company_branch" slot-scope="record">
        <div class="dotted-phrase">{{ record.company_branch.name }}</div>
      </div>

      <div slot="pay_day" slot-scope="record">
        {{ record.pay_day | formatDate }}
      </div>

      <div slot="due_date" slot-scope="record">
        {{ record.due_date | formatDate }}
      </div>

      <div slot="created" slot-scope="record">
        {{ record.created | formatDateTime }}
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a class="edit" @click="viewInvoice(record)">
            <a-icon slot="prefix" :type="'eye-svg'" />
          </a>
        </div>
      </div>
    </aTable>

    <aModal
      :footer="false"
      :width="$root.windowWidth > 960 ? '1020px' : '100%'"
      v-model="openInvoiceModal"
      @cancel="onCloseInvoiceModal"
    >
      <template #title>
        DETALHES DA FATURA - {{ theInvoice.name }}
        <a-tag class="ml-20">{{ theInvoice.invoice_code }}</a-tag>
      </template>
      <InvoiceModal v-if="openInvoiceModal" :invoice="theInvoice" />
    </aModal>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import invoicesMixins from "@/components/finances/mixins/invoicesMixins";
import InvoiceModal from "@/components/finances/modals/InvoiceModal.vue";
import InvoicesListFiltersCollapse from "@/components/finances/collapses/InvoicesListFiltersCollapse.vue";

export default {
  name: "InvoicesList",
  components: { InvoiceModal, InvoicesListFiltersCollapse },
  mixins: [formatThings, invoicesMixins],
  data() {
    return {
      openInvoiceModal: false,
      openMergeInvoicesModal: false,
      theInvoice: "",
    };
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    viewInvoice(invoice) {
      this.openInvoiceModal = true;
      this.theInvoice = invoice;
    },
    onClickFilterInvoices(filters) {
      this.invoice.filters = filters;
      this.getInvoices();
    },
    onCloseInvoiceModal() {
      this.openInvoiceModal = false;
    },
  },
};
</script>
