export default {
  data() {
    return {
      invoice: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          id: "",
          invoiceCode: "",
          users: {
            selected: [],
          },
          status: {
            selected: [],
          },
          productSupplier: undefined,
          companies: {
            list: [],
            selected: undefined,
          },
          companyBranches: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      tempInvoice: {},
      invoicesTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Código da Fatura",
          dataIndex: "invoice_code",
          key: "invoice_code",
          width: 150,
          sorter: true,
        },
        {
          title: "Fornecedor",
          scopedSlots: { customRender: "product_supplier" },
          width: 160,
        },
        {
          title: "Valor",
          scopedSlots: { customRender: "value" },
          width: 130,
        },
        {
          title: "Saldo",
          scopedSlots: { customRender: "balance_value" },
          width: 130,
        },
        {
          title: "Dt. Pagamento",
          scopedSlots: { customRender: "pay_day" },
          width: 120,
        },
        {
          title: "Dt. Vencimento",
          scopedSlots: { customRender: "due_date" },
          width: 120,
        },
        {
          title: "Empresa",
          scopedSlots: { customRender: "company" },
          width: 100,
        },

        {
          title: "Filial",
          scopedSlots: { customRender: "company_branch" },
          width: 140,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 100,
          sorter: true,
        },
        {
          title: "Data de cadastro",
          width: 150,
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          width: 80,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 60,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    changeInvoicePage(current) {
      this.invoice.pagination.page = current;
      this.getInvoices();
    },
    invoiceTableChange(pagination, filters, sorter) {
      console.log(filters, pagination);
      this.invoice.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.invoice.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getInvoices();
    },
    invoiceChangePageSize(current, pageSize) {
      this.invoice.pagination.page = current;
      this.invoice.pagination.perPage = pageSize;
      this.getInvoices();
    },
    invoiceFilters() {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (this.invoice.filters.invoiceCode) {
        filters += `&invoice_code=${this.invoice.filters.invoiceCode}`;
        queryParams.set("invoice_code", `${this.invoice.filters.invoiceCode}`);
      }

      if (this.invoice.filters.users.selected.length > 0)
        filters += `&user_id=${this.invoice.filters.users.selected}`;

      if (this.invoice.filters.status.selected.length > 0)
        filters += `&status=${this.invoice.filters.status.selected}`;

      if (this.invoice.filters.productSupplier)
        filters += `&product_supplier_id=${this.invoice.filters.productSupplier}`;

      if (this.invoice.filters.companies.selected)
        filters += `&company_id=${this.invoice.filters.companies.selected}`;

      if (this.invoice.filters.companyBranches.selected.length > 0)
        filters += `&company_branch_id=${this.invoice.filters.companyBranches.selected}`;

      if (this.invoice.filters.period.selected.length > 0) {
        filters += `&period=${this.invoice.filters.period.selected[0]}|${this.invoice.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.invoice.filters.period.selected[0]}|${this.invoice.filters.period.selected[1]}`
        );
      }

      if (this.invoice.filters.id) filters += `&id=${this.invoice.filters.id}`;

      filters += `&order=${this.invoice.filters.order}&order-by=${this.invoice.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    async getInvoiceById(id) {
      try {
        const data = await this.$http.get(`/invoice/details?id=${id}`);
        this.invoice.details = data.data;
        this.tempInvoice = data.data.raw;
        return data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.invoice.details = {};
      }
    },
    async getInvoices(samePage) {
      this.invoice.loading = true;
      try {
        const { data } = await this.$http.get(
          `/invoice/list?page=${this.invoice.pagination.page}&per_page=${
            this.invoice.pagination.perPage
          }${this.invoiceFilters()}`
        );

        this.invoice.list = data.data;
        this.invoice.meta = data.meta;
        this.invoice.pagination.page = data.meta.next_page;
        this.invoice.pagination.total = data.meta.total;

        if (!samePage) {
          this.invoice.pagination.page = 1;
        }
      } catch (e) {
        console.log(e);
        this.invoice.list = [];
        this.invoice.meta = {
          total: 0,
          total_available: "0,00",
          total_general: 0,
          total_pages: 0,
          total_spent: "0,00",
          total_value: "0,00",
        };
      } finally {
        this.invoice.loading = false;
      }
    },
  },
};
